<template>
  <div class="admin-login-container">
    <div class="background-animation"></div>
    <div class="login-card" :class="{ 'logging-in': loading }">
      <h2 class="login-title">Admin Login</h2>
      <el-form @submit.prevent="handleLogin" :model="form" :rules="rules" ref="loginForm">
        <el-form-item prop="username">
          <el-input
              v-model="form.username"
              placeholder="Username"
              :prefix-icon="User"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              v-model="form.password"
              type="password"
              placeholder="Password"
              :prefix-icon="Lock"
              show-password
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit" :loading="loading" class="login-button" :disabled="loading">
            <span class="button-text">{{ loading ? 'Logging in...' : 'Login' }}</span>
            <span class="button-icon"></span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from 'vue'
import {useRouter} from 'vue-router'
import {ElMessage} from 'element-plus'
import {login} from '@/admin-api'; // 引入统一的 login API
import {Lock, User} from '@element-plus/icons-vue' // 引入图标

export default {
  name: 'AdminLogin',
  setup() {
    const router = useRouter()
    const loginForm = ref(null)
    const loading = ref(false)

    const form = reactive({
      username: '',
      password: ''
    })

    const rules = {
      username: [
        { required: true, message: 'Please input your username', trigger: 'blur' },
        { min: 3, max: 20, message: 'Length should be 3 to 20 characters', trigger: 'blur' }
      ],
      password: [
        { required: true, message: 'Please input your password', trigger: 'blur' },
        { min: 3, message: 'Password must be at least 3 characters', trigger: 'blur' }
      ]
    }

    const handleLogin = async () => {
      if (!loginForm.value) return
      try {
        await loginForm.value.validate() // 验证表单
        loading.value = true
        const response = await login(form.username, form.password);
        const token = response.data.token;
        if (token) {
          localStorage.setItem('token', token);
          ElMessage.success('Login successful!');
          router.push('/admin');
        }
      } catch (error) {
        ElMessage.error('Login failed. Please check your credentials.');
      } finally {
        loading.value = false
      }
    };

    return {
      form,
      rules,
      loginForm,
      loading,
      handleLogin,
      User,
      Lock
    };
  }
}
</script>

<style scoped>
.admin-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(45deg, #c055e8, #c05511);
  overflow: hidden;
  position: relative;
}

.background-animation {
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200%;
  background: transparent url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation .2s infinite;
  opacity: .9;
  visibility: visible;
}

@keyframes bg-animation {
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
}

.login-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  width: 100%;
  max-width: 400px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.login-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 25px rgba(31, 38, 135, 0.5);
}

.login-title {
  color: white;
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.login-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  background: linear-gradient(45deg, #FF3366, #BA265D);
  border: none;
  color: white;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.login-button .button-text {
  position: relative;
  z-index: 1;
}

.login-button .button-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #FF1166, #BB265D);
  transition: all 0.3s ease;
  transform: translateX(-100%);
}

.login-button:hover .button-icon {
  transform: translateX(0);
}

.el-input {
  margin-bottom: 20px;
}

.el-input__inner {
  background: rgba(255, 255, 255, 0.2) !important;
  border: none !important;
  color: white !important;
}

.el-input__inner::placeholder {
  color: rgba(255, 255, 255, 0.7) !important;
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.login-card {
  animation: fadeInUp 0.5s ease-out;
}

.logging-in {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Responsive design */
@media (max-width: 480px) {
  .login-card {
    padding: 30px;
    margin: 20px;
  }

  .login-title {
    font-size: 24px;
  }
}
</style>
