// src/admin-api.js
import axios from 'axios';

// 创建 axios 实例
const api = axios.create({
   baseURL: 'https://wol.alimzs.com/api',
 // baseURL: 'http://127.0.0.1:8080/api',
    timeout: 10000,
    //withCredentials: true, // 启用凭证
});


// 请求拦截器 - 在每次请求前加入 token，排除掉登录请求
api.interceptors.request.use(
    (config) => {
        // 检查是否是登录请求
        if (config.url !== '/admin/login') {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);


// 响应拦截器 - 处理全局的响应错误
api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('API 请求错误: ', error);
        return Promise.reject(error);
    }
);

// 定义用户相关的 API 请求
export const fetchUsers = () => api.get('/admin/users'); // 获取用户信息

// 创建新用户
export const createUser = (userData) => api.post('/admin/users', userData);

// 更新用户信息
export const updateUser = (userId, userData) => api.put(`/admin/users/${userId}`, userData);

// 软删除用户（禁用）
export const softDeleteUser = (userId) => api.delete(`/admin/users/soft-delete/${userId}`);

// 硬删除用户
export const hardDeleteUser = (userId) => api.delete(`/admin/users/${userId}`);

// 切换用户禁用/启用状态
export const toggleUserStatus = (userId, isDisabled) => {
    // 注意这里 true 代表禁用，false 代表启用
    return api.put(`/admin/users/${userId}/status`, null, { params: { is_disabled: isDisabled } });
};

export const entries=()=>api.get('admin/entries')

// 登录
export const login = (username, password) => api.post('/admin/login', { username, password });

export default api;
