<template>
    <div class="firmware-list">
        <h1>我的固件</h1>
        <!-- 固件和设备的表格展示 -->
        <table class="table">
            <thead>
                <tr>
                    <th>固件 ID</th>
                    <th>设备 ID</th>
                    <th>IP 地址</th>
                    <th>MAC 地址</th>
                    <th>状态</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="firmware in firmwares" :key="firmware.ID" @click="selectFirmware(firmware)">
                    <td>{{ firmware.ID }}</td>
                    <td>{{ firmware.DeviceID }}</td>
                    <td>{{ firmware.IPAddress }}</td>
                    <td>{{ firmware.MacAddress }}</td>
                    <td>{{ firmware.Online ? '在线' : '离线' }}</td>
                </tr>
            </tbody>
        </table>

        <!-- 设备列表 -->
        <div v-if="selectedFirmware" class="device-list">
            <h3>设备列表（固件 ID: {{ selectedFirmware.ID }}）</h3>
            <table class="table">
                <thead>
                    <tr>
                        <th>设备 ID</th>
                        <th>IP 地址</th>
                        <th>MAC 地址</th>
                        <th>状态</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="device in devices" :key="device.DeviceID">
                        <td>{{ device.DeviceID }}</td>
                        <td>{{ device.IPAddress }}</td>
                        <td>{{ device.MacAddress }}</td>
                        <td>{{ device.Online ? "在线" : "离线" }}</td>
                        <td>
                            <button :disabled="device.wolLoading" @click.stop="wol(device.MacAddress)">
                                {{ device.wolLoading ? '正在唤醒...' : 'WOL唤醒' }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import { getFirmwares, getDeviceList, wakeOnLan } from "@/user-api"; // 引入 API 函数
import { ElMessage } from "element-plus"; // 引入 Element Plus 的消息提示

export default {
    data() {
        return {
            firmwares: [],
            selected: null,
            selectedFirmware: null,
            devices: [],
        };
    },
    mounted() {
        this.fetchFirmwares();
    },
    methods: {
        async fetchFirmwares() {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    ElMessage.error("未登录，请先登录");
                    this.$router.push("/login");
                    return;
                }

                const response = await getFirmwares(token);
                if (response.data && response.data.firmwares) {
                    this.firmwares = response.data.firmwares;
                } else {
                    ElMessage.warning("没有找到固件数据");
                }
            } catch (error) {
                console.error("获取固件列表失败: ", error);
                ElMessage.error("获取固件列表失败");
            }
        },

        async selectFirmware(firmware) {
            this.selected = firmware.ID;
            this.selectedFirmware = firmware;

            try {
                const token = localStorage.getItem("token");
                const response = await getDeviceList(token, firmware.ID);

                if (response.data && response.data.devices) {
                    this.devices = response.data.devices;
                } else {
                    this.devices = [];
                    ElMessage.warning("该固件下没有设备");
                }
            } catch (error) {
                console.error("获取设备列表失败: ", error);
                ElMessage.error("获取设备列表失败");
            }
        },

        async wol(macAddress) {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    ElMessage.error("未登录，请先登录");
                    this.$router.push("/login");
                    return;
                }

                // 设置设备正在加载的标志
                const device = this.devices.find(d => d.MacAddress === macAddress);
                device.wolLoading = true;

                const response = await wakeOnLan(token, { MacAddress: macAddress });

                if (response.data.success) {
                    ElMessage.success("WOL唤醒请求已发送");
                } else {
                    ElMessage.error("WOL唤醒失败");
                }

                // 重置设备加载状态
                device.wolLoading = false;
            } catch (error) {
                console.error("WOL唤醒请求失败: ", error);
                ElMessage.error("WOL唤醒请求失败");
            }
        }
    }
};
</script>

<style scoped>
.firmware-list {
    padding: 20px;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: center;
}

button:hover {
    background-color: #f1f1f1;
}

.device-list {
    margin-top: 20px;
}
</style>
