<template>
  <el-container style="height: 100vh;">
    <!-- 顶部导航栏 -->
    <el-header class="header">
      <div class="logo">管理系统</div> <!-- 左侧标题 -->
      <el-button type="primary" @click="handleLogout" class="logout-button">退出</el-button> <!-- 右侧退出按钮 -->
    </el-header>

    <el-container>
      <!-- 左侧导航栏 -->
      <el-aside width="220px" class="sidebar">
        <el-menu
            default-active="/admin/home"
            class="el-menu-vertical-demo"
            background-color="#2D3748"
            text-color="#A0AEC0"
            active-text-color="#63B3ED"
            :border="false"
        >
          <el-menu-item index="/admin/home">
            <router-link to="/admin/home" class="menu-link">
              <i class="el-icon-house"></i> 首页
            </router-link>
          </el-menu-item>
          <el-menu-item index="/admin/user">
            <router-link to="/admin/user" class="menu-link">
              <i class="el-icon-user"></i> 用户管理
            </router-link>
          </el-menu-item>
          <el-menu-item index="/admin/api">
            <router-link to="/admin/api" class="menu-link">
              <i class="el-icon-picture"></i> 接口管理
            </router-link>
          </el-menu-item>
          <el-menu-item index="/admin/question">
            <router-link to="/admin/question" class="menu-link">
              <i class="el-icon-message"></i> 对话管理
            </router-link>
          </el-menu-item>
          <el-menu-item index="/admin/log">
            <router-link to="/admin/log" class="menu-link">
              <i class="el-icon-document"></i> 日志
            </router-link>
          </el-menu-item>
          <el-menu-item index="/admin/teststream">
            <router-link to="/admin/teststream" class="menu-link">
              <i class="el-icon-refresh"></i> HTTP流对话测试
            </router-link>
          </el-menu-item>
          <el-menu-item index="/admin/streamfile">
            <router-link to="/admin/streamfile" class="menu-link">
              <i class="el-icon-upload"></i> 文件流上传测试
            </router-link>
          </el-menu-item>
          <el-menu-item index="/admin/setting">
            <router-link to="/admin/setting" class="menu-link">
              <i class="el-icon-setting"></i> 设置
            </router-link>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <!-- 主内容区域，嵌套路由 -->
      <el-main class="main-content">
        <router-view></router-view> <!-- 路由视图 -->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {useRouter} from 'vue-router';

export default {
  name: 'UserManagement',
  setup() {
    const router = useRouter();

    const handleLogout = () => {
      localStorage.removeItem('token'); // 移除 token
      router.push('/login'); // 跳转到登录页面
    };

    return {
      handleLogout,
    };
  },
};
</script>

<style scoped>
/* 顶部导航栏样式 */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background-color: #2D3748;
  color: #E2E8F0;
  height: 64px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.logout-button {
  font-size: 14px;
}

/* 左侧标题样式 */
.logo {
  font-size: 22px;
  font-weight: bold;
  color: #63B3ED;
}

/* 左侧导航栏样式 */
.sidebar {
  background-color: #2D3748;
  padding-top: 16px;
  color: white;
  height: calc(100vh - 64px);
}

.menu-link {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  font-size: 15px;
  border-radius: 8px;
}


.el-menu {
  border-right: none;
}

.el-menu-item {
  transition: all 0.3s;
  margin-bottom: 8px;
}

.el-menu-item:hover {
  background-color: #4A5568;
}

.el-menu-item .el-icon {
  margin-right: 10px;
  font-size: 18px;
}

/* 主内容区域样式 */
.main-content {
  padding: 24px;
  background-color: #F7FAFC;
  height: calc(100vh - 64px);
  overflow-y: auto;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}
</style>
