<template>
  <div class="device-list">
    <h1>设备列表</h1>
    <ul>
      <li v-for="device in devices" :key="device.DeviceID">
        <p>设备 ID: {{ device.DeviceID }}</p>
        <p>MAC 地址: {{ device.MacAddress }}</p>
        <p>IP 地址: {{ device.IPAddress }}</p>
        <p>设备状态: {{ device.Online ? '在线' : '离线' }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { getDeviceList } from "@/user-api"; // 假设你有一个获取设备列表的 API 函数
import { ElMessage } from "element-plus"; // 引入 Element Plus 的消息提示

export default {
  data() {
    return {
      devices: [] // 设备列表
    };
  },
  mounted() {
    const firmwareId = this.$route.params.firmwareId; // 获取固件 ID
    this.fetchDevices(firmwareId);
  },
  methods: {
    // 获取固件下的设备列表
    async fetchDevices(firmwareId) {
      try {
        const token = localStorage.getItem("token"); // 获取 token
        if (!token) {
          ElMessage.error("未登录，请先登录");
          this.$router.push("/login"); // 如果没有 token，跳转到登录页
          return;
        }

        const response = await getDeviceList(token, firmwareId); // 调用 API 获取设备列表

        if (response.data && response.data.devices) {
          this.devices = response.data.devices;
        } else {
          this.devices = [];
          ElMessage.warning("该固件下没有设备");
        }
      } catch (error) {
        console.error("获取设备列表失败: ", error);
        ElMessage.error("获取设备列表失败");
      }
    }
  }
};
</script>

<style scoped>
.device-list {
  padding: 20px;
}
</style>
