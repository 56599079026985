import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 创建 axios 实例
const axiosInstance = axios.create({
  baseURL: 'http://127.0.0.1:9090',
  timeout: 5000 // 设置超时时间
})

// 创建自定义插件
const axiosPlugin = {
  install: (app) => {
    app.config.globalProperties.$axios = axiosInstance
    app.provide('axios', axiosInstance)
  }
}

const app = createApp(App)

app.use(router)
app.use(ElementPlus)
app.use(axiosPlugin) // 使用自定义的 axios 插件

app.mount('#app')