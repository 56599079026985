import { createRouter, createWebHistory } from 'vue-router';

import UserLogin from '@/views/UserLogin.vue';
import UserHome from '@/views/UserHome.vue';  // 确保 UserHome 组件已正确导入
import FirmwareList from '@/views/FirmwareList.vue';
import DeviceList from '@/views/DeviceList.vue';

import AdminLogin from '@/views/AdminLogin.vue';
import AdminUserManagement from '@/views/AdminUserManagement.vue';
import AdminHome from '@/views/AdminHome.vue';
import Admin from '@/views/Admin.vue';

const routes = [
  { path: '/', redirect: '/login' }, 

  // 用户登录相关路由
  { path: '/login', component: UserLogin },
  
  {
    path: '/user',
    component: UserHome, // UserHome 作为容器组件
    children: [
      { path: '', redirect: 'firmware' }, // 默认跳转到固件列表
      { path: 'firmware', component: FirmwareList }, // `/user/firmware` 渲染 `FirmwareList`
      { path: 'firmware/:id/devices', component: DeviceList } // `/user/firmware/:id/devices` 渲染设备列表
    ],
  },

  // 管理员相关路由
  { path: '/admin/login', component: AdminLogin },

  {
    path: '/admin',
    component: AdminHome,  // 假设你想使用 AdminHome 作为管理员的主界面
    children: [
      { path: '', redirect: 'home' }, // 默认跳转到首页
      { path: 'home', name: 'AdminHome', component: AdminHome },
      { path: 'management', name: 'AdminUserManagement', component: AdminUserManagement },
      { path: 'admin', name: 'Admin', component: Admin }
    ],
  }
];

// 创建 Vue Router 实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 全局路由守卫，检查用户是否已登录
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token'); // 从 localStorage 中获取 token
  const isAuthenticated = !!token; // 检查 token 是否存在，作为是否已登录的标志

  // 如果尝试访问受保护的路由（如 /admin 或 /user），并且没有登录，则重定向到登录页面
  if ((to.path.startsWith('/admin') || to.path.startsWith('/user')) && !isAuthenticated) {
    next('/login'); // 跳转到登录页面
  } else {
    next(); // 继续正常访问
  }
});

export default router;
