<template>
    <div class="user">
      <div class="header">
        <h2>用户管理</h2>
        <el-button type="primary" @click="openAddUserDialog">新增用户</el-button>
      </div>
  
      <!-- 用户列表表格 -->
      <el-table :data="users" v-loading="loading" style="width: 100%">
        <el-table-column prop="ID" label="用户ID" width="100"></el-table-column>
        <el-table-column prop="WeChatOpenID" label="微信OpenID"></el-table-column>
        <el-table-column prop="Username" label="用户名"></el-table-column>
        <el-table-column prop="QuestionCount" label="提问数"></el-table-column>
        <el-table-column prop="CreatedAt" label="创建时间"></el-table-column>
        <el-table-column prop="UpdatedAt" label="更新时间"></el-table-column>
        <el-table-column label="禁用状态">
          <template v-slot="scope">
            <el-switch
                v-model="scope.row.isDisabled"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="toggleStatus(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
  
        <!-- 操作列 -->
        <el-table-column label="操作" width="180">
          <template v-slot="scope">
            <div class="button-group">
              <el-button size="small" @click="editUser(scope.row)">编辑</el-button>
              <el-button size="small" type="danger" @click="openDeleteDialog(scope.row.ID)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
  
      <!-- 新增用户对话框 -->
      <el-dialog title="新增用户" v-model="addUserDialogVisible">
        <el-form :model="newUser">
          <el-form-item label="用户名">
            <el-input v-model="newUser.Username"></el-input>
          </el-form-item>
          <el-form-item label="微信OpenID">
            <el-input v-model="newUser.WeChatOpenID"></el-input>
          </el-form-item>
          <el-form-item label="提问数">
            <el-input v-model="newUser.QuestionCount" type="number"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button @click="addUserDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="createUser">确认</el-button>
        </template>
      </el-dialog>
  
      <!-- 编辑用户对话框 -->
      <el-dialog title="编辑用户" v-model="editUserDialogVisible">
        <el-form :model="editUserData">
          <el-form-item label="用户名">
            <el-input v-model="editUserData.Username"></el-input>
          </el-form-item>
          <el-form-item label="微信OpenID">
            <el-input v-model="editUserData.WeChatOpenID"></el-input>
          </el-form-item>
          <el-form-item label="提问数" prop="QuestionCount" :rules="[{ required: true, type: 'number', message: '请输入有效的数字', trigger: 'blur' }]">
            <el-input v-model.number="editUserData.QuestionCount" type="number"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button @click="editUserDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="updateUser">确认</el-button>
        </template>
      </el-dialog>
  
  
      <!-- 删除用户对话框 -->
      <el-dialog title="删除用户" v-model="deleteDialogVisible">
        <el-radio-group v-model="deleteType">
          <el-radio label="soft">软删除</el-radio>
          <el-radio label="hard">硬删除</el-radio>
        </el-radio-group>
        <template #footer>
          <el-button @click="deleteDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleDelete">确认</el-button>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import {onMounted, ref} from 'vue';
  import {createUser, fetchUsers, hardDeleteUser, softDeleteUser, toggleUserStatus, updateUser} from '@/admin-api';
  import {ElMessage} from 'element-plus';
  
  export default {
    name: 'AdminUser',
    setup() {
      const users = ref([]);
      const loading = ref(false);
      const addUserDialogVisible = ref(false);
      const editUserDialogVisible = ref(false);
      const deleteDialogVisible = ref(false);
      const deleteType = ref('soft');
      const selectedUserId = ref(null);
      const newUser = ref({
        Username: '',
        WeChatOpenID: '',
        QuestionCount: 0,
      });
      const editUserData = ref({
        ID: '',
        Username: '',
        WeChatOpenID: '',
        QuestionCount: 0,
      });
  
      const getUsers = async () => {
    loading.value = true;
    try {
      const response = await fetchUsers();
      users.value = response.data.map(user => ({
        ...user,
        isDisabled: user.IsDisabled // 转换字段名
      }));
    } catch (error) {
      ElMessage.error('获取用户数据失败');
    } finally {
      loading.value = false;
    }
  };
  
  
      const openAddUserDialog = () => {
        addUserDialogVisible.value = true;
      };
  
      const createUserHandler = async () => {
        try {
          newUser.value.QuestionCount = parseInt(newUser.value.QuestionCount) || 0; // 确保为整数
          await createUser(newUser.value);
          ElMessage.success('用户创建成功');
          getUsers();
          addUserDialogVisible.value = false;
        } catch (error) {
          ElMessage.error('创建用户失败');
        }
      };
  
      const updateUserHandler = async () => {
        try {
          editUserData.value.QuestionCount = parseInt(editUserData.value.QuestionCount) || 0; // 确保为整数
          await updateUser(editUserData.value.ID, editUserData.value);
          ElMessage.success('用户更新成功');
          getUsers();
          editUserDialogVisible.value = false;
        } catch (error) {
          ElMessage.error('更新用户失败');
        }
      };
  
  
      const editUser = (user) => {
        editUserData.value = { ...user };
        editUserDialogVisible.value = true;
      };
      const openDeleteDialog = (userId) => {
        selectedUserId.value = userId;
        deleteDialogVisible.value = true;
      };
  
      const handleDelete = async () => {
        try {
          if (deleteType.value === 'soft') {
            await softDeleteUser(selectedUserId.value);
          } else {
            await hardDeleteUser(selectedUserId.value);
          }
          ElMessage.success('用户删除成功');
          getUsers();
          deleteDialogVisible.value = false;
        } catch (error) {
          ElMessage.error('删除用户失败');
        }
      };
  
      const toggleStatus = async (user) => {
        try {
          // 直接传递当前状态
          await toggleUserStatus(user.ID, user.isDisabled);
          ElMessage.success(`用户已${user.isDisabled ? '禁用' : '启用'}`);
        } catch (error) {
          ElMessage.error('切换用户状态失败');
        }
      };
  
  
      onMounted(() => {
        getUsers();
      });
  
      return {
        users,
        loading,
        newUser,
        addUserDialogVisible,
        editUserDialogVisible,
        deleteDialogVisible,
        deleteType,
        selectedUserId,
        editUserData,
        openAddUserDialog,
        createUser: createUserHandler,
        editUser,
        updateUser: updateUserHandler,
        openDeleteDialog,
        handleDelete,
        toggleStatus,
      };
    },
  };
  </script>
  
  <style scoped>
  .user {
    padding: 20px;
    background-color: #f5f7fa;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid #ebeef5;
  }
  
  h2 {
    margin: 0;
    font-weight: bold;
    color: #409eff;
  }
  
  .el-button {
    border-radius: 5px;
  }
  
  .el-table {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  }
  
  .el-table th, .el-table td {
    padding: 10px;
  }
  
  .el-table th {
    background-color: #f2f6fc;
    color: #333;
    font-weight: 600;
  }
  
  .el-table td {
    background-color: #fff;
    transition: background-color 0.3s;
  }
  
  .el-table td:hover {
    background-color: #f9fbff;
  }
  
  .button-group {
    display: inline-flex;
    gap: 10px;
  }
  
  .el-dialog__header {
    background-color: #f2f6fc;
    border-bottom: 1px solid #ebeef5;
  }
  
  .dialog-footer {
    text-align: right;
    padding-top: 10px;
    border-top: 1px solid #ebeef5;
  }
  
  .el-form-item {
    margin-bottom: 16px;
  }
  
  .el-input {
    border-radius: 5px;
  }
  </style>
  
  