<template>
  <div class="home">
    <h2>欢迎来到管理系统首页</h2>
    <p>这里是管理系统的首页内容。</p>
  </div>
</template>

<script>
export default {
  name: 'AdminHome',
};
</script>

<style scoped>
.home {
  padding: 20px;
  text-align: center;
}

h2 {
  color: #333;
}
</style>
