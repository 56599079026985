// src/user-api.js
import axios from 'axios';

// 创建 axios 实例
const api = axios.create({
    baseURL: 'https://wol.alimzs.com/api',
 // baseURL: 'http://127.0.0.1:8080/api',
    timeout: 10000,
    //withCredentials: true, // 启用凭证
});


// 请求拦截器 - 在每次请求前加入 token，排除掉登录请求
api.interceptors.request.use(
    (config) => {
        // 检查是否是登录请求
        if (config.url !== '/user/login') {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);


// 响应拦截器 - 处理全局的响应错误
api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('API 请求错误: ', error);
        return Promise.reject(error);
    }
);


// 登录
export const login = (username, password) => api.post('/login', { username, password });

// 注册
export const register = (username, password) => api.post('/register', { username, password });

// 移除固件
export const removeFirmware = async (token, firmwareId) => {
    return api.delete(`/user/firmware/${firmwareId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
// 移除设备
export const removeDevice = async (token, deviceId) => {
    return api.delete(`/user/device/${deviceId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

//WOL 唤醒设备 POST /api/user/wol 
//唤醒结构Json {"macAddress": "string"}

export const wakeOnLan = async (token, deviceData) => {
    return api.post(`/user/wol`, deviceData, {  // 使用 api 实例而不是 axios
        headers: { Authorization: `Bearer ${token}` },
    });
}



//固件重启  POST /api/user/reboot
export const reboot = async (token, deviceData) => {
    return axios.post(`/api/devices/${deviceData.DeviceID}/reboot`, deviceData, {
        headers: { Authorization: `Bearer ${token}` },
    });
};
//固件重置 POST /api/user/reset
export const reset = async (token, deviceData) => {
    return axios.post(`/api/devices/${deviceData.DeviceID}/reset`, deviceData, {
        headers: { Authorization: `Bearer ${token}` },
    });
}



// 获取用户固件列表
export const getFirmwares = async (token) => {
    try {
      const response = await api.get('/user/firmwareList', {  // 这里无需再加 baseURL，因为已经在实例中指定
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response;  // 返回完整的响应数据
    } catch (error) {
      console.error('API 请求失败:', error);
      throw error;  // 抛出异常以便在组件中处理
    }
  };

// 获取设备列表
  export function getDeviceList(token, firmwareId) {
    return api.get(`/user/deviceList/${firmwareId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json"
      }
    });
  }
// 获取用户信息
export default api;
